import loadable from '@loadable/component'
import MigrationDB from '@pages/db-migration'
import {
  APP_FEATURES,
  FROM_MODULE_TYPE,
  IME_SERVICE_TYPE,
  MN_SERVICE_TYPE,
  ROLE_NAME,
  SUPP_SERVICE_TYPE,
} from '@utils/constants'
import { APP_ROUTES } from '@utils/routesConfig'
import { translate } from 'translator'
// import talkingPointCategory from '@pages/settings/talking-point-category'
import ClientActiveCount from '@pages/system-reports/client-active-count'
import { RouteConfig } from './interface'

const AdHocTemplate = loadable(() => import('@pages/settings/adhoc-templates'))
const AssessmentList = loadable(() => import('@pages/assessment/assessment-list'))
const PMSIntegration = loadable(() => import('@pages/pms-integration'))
const VideoSession = loadable(() => import('@pages/video-meeting'))
const AssessmentDetail = loadable(() => import('@pages/assessment/assessment-details'))
const SuppReportDetail = loadable(() => import('@pages/supplementary/supplementary-details'))
const AdminServiceFee = loadable(() => import('@pages/settings/service-fee'))
const NDISServiceItem = loadable(() => import('@pages/settings/ndis-service-item'))
const AMAFeeCodes = loadable(() => import('@pages/settings/ama-fee-codes'))
const TalkingPoint = loadable(() => import('@pages/settings/talking-point'))
const TalkingPointCategory = loadable(() => import('@pages/settings/talking-point-category'))

const SpecialistList = loadable(() => import('@pages/specialist/specialist-list'))
const AdminLoginUser = loadable(() => import('@pages/user/user-list'))
const UserGroup = loadable(() => import('@pages/user-group'))
const Dashboard = loadable(() => import('@pages/dashboard'))
const DashboardDisbursement = loadable(() => import('@pages/dashboardDisbursement'))
const SessionDetail = loadable(() => import('@pages/calendar/AppointmentSessions'))
const InvoiceList = loadable(() => import('@pages/invoice/invoice-list'))
const InvoiceDetail = loadable(() => import('@pages/invoice/invoice-details'))
const SpecialistRemittance = loadable(() => import('@pages/specialist/specialist-remittance'))
const DashboardRedirect = loadable(() => import('./DashboardRedirect'))
const CentreList = loadable(() => import('@pages/centre/centre-list'))
const CaseManagerList = loadable(() => import('@pages/case-manager/case-manager-list'))
const CaseManagerDetail = loadable(() => import('@pages/case-manager/case-manager-details'))
const CaseManagerProfile = loadable(() => import('@pages/case-manager/case-manager-profile'))
const ContractorDetail = loadable(() => import('@pages/contractor/contractor-details'))
const ContractorList = loadable(() => import('@pages/contractor/contractor-list'))
const CompanieList = loadable(() => import('@pages/company/company-list'))
const CompanyDetail = loadable(() => import('@pages/company/company-details'))
const ClientList = loadable(() => import('@pages/client/client-list'))
const ClientDetail = loadable(() => import('@pages/client/client-details'))
const DoctorRemittance = loadable(() => import('@pages/specialist/specialist-remittance'))
const MailTemplateList = loadable(() => import('@pages/settings/mail-template'))
const FundingProviderList = loadable(() => import('@pages/disbursement/funding-provider/listing'))
const MyProfile = loadable(() => import('@pages/my-detail'))
const SpecialistDetail = loadable(() => import('@pages/specialist/specialist-details'))
const SystemConfig = loadable(() => import('@pages/settings/system-config'))
const CentreDetail = loadable(() => import('@pages/centre/centre-details'))
const MarketingCampaignList = loadable(() => import('@pages/marketing-campaign/list'))
const MarketingCampaignDetail = loadable(() => import('@pages/marketing-campaign/details'))
const SaleTargetList = loadable(() => import('@pages/settings/sales-target'))
const LookupConfig = loadable(() => import('@pages/settings/lookup-configs'))
const HolidayState = loadable(() => import('@pages/public-holiday'))
const AccountCode = loadable(() => import('@pages/payment-account-code'))
const InquiryCentre = loadable(() => import('@pages/inquiry-centre'))
const AppointmentSearch = loadable(() => import('@pages/search-options/appointment-search/index'))
const ClinicalRecordDetail = loadable(() => import('@pages/clinical-record/clinical-record-detail/index'))
const MedNegDetail = loadable(() => import('@pages/med-neg/med-neg-detail/index'))
const MedNegList = loadable(() => import('@pages/med-neg/med-neg-list'))
const SupplementaryReportList = loadable(() => import('@pages/supplementary/supplementary-list'))
const ClinicalRecordsRetrievalList = loadable(() => import('@pages/clinical-record/clinical-record-list'))
const SubscriptionPage = loadable(() => import('@pages/subscriptions'))
const CasesSearch = loadable(() => import('@pages/search-options/cases-search'))
const ActionRequiredSearch = loadable(() => import('@pages/search-options/action-required-search/index'))
const DocsSearch = loadable(() => import('@pages/search-options/docs-search'))
const TenantList = loadable(() => import('@pages/tenants/tenants-list'))
const RoleList = loadable(() => import('@pages/roles/roles-list'))
const DoctorProfile = loadable(() => import('@pages/doctor-profile'))
const LawPracticeSoftwares = loadable(() => import('@pages/law-practice-softwares'))
const PMSMatters = loadable(() => import('@pages/pms-matters'))
const FacilityRequestList = loadable(() => import('@pages/disbursement/facility-request/listing'))
const FacilityRequestDetail = loadable(() => import('@pages/disbursement/facility-request/detail'))
const ListingFacilityAgreement = loadable(() => import('@pages/disbursement/facility-agreements/listing'))
const FacilityTransactionList = loadable(() => import('@pages/disbursement/facility-transactions/listing'))
const DisbursementFundingList = loadable(() => import('@pages/disbursement/disbursement-funding/listing'))
const AddEditFacilityAgreement = loadable(() => import('@pages/disbursement/facility-agreements/detail'))
const FacilityTransactionDetail = loadable(() => import('@pages/disbursement/facility-transactions/detail'))
const DisbursementFundingDetail = loadable(() => import('@pages/disbursement/disbursement-funding/detail'))
const LMQList = loadable(() => import('@pages/settings/lmq-criteria/listing'))
const LMQDetail = loadable(() => import('@pages/settings/lmq-criteria/detail'))
const TimeTrackingList = loadable(() => import('@pages/task-timer/task-timer-list'))
const TimeTrackingDetail = loadable(() => import('@pages/task-timer/task-timer-details'))
const ClioAppPage = loadable(() => import('@pages/law-practice-softwares/clio-app/AppPage'))
const ActionStepAppPage = loadable(() => import('@pages/law-practice-softwares/actionstep-app/AppPage'))
const SmokeBallAppPage = loadable(() => import('@pages/law-practice-softwares/smokeball-app/AppPage'))
const LeapAppPage = loadable(() => import('@pages/law-practice-softwares/leap-app/AppPage'))
const PEAppPage = loadable(() => import('@pages/law-practice-softwares/practice-evolve-app/AppPage'))
const NDISList = loadable(() => import('@pages/ndis/ndis-list'))
const NDISDetails = loadable(() => import('@pages/ndis/details'))
const StaffList = loadable(() => import('@pages/staff/staff-list'))
const EmailTrackingList = loadable(() => import('@pages/notifications/emails-tracking'))
const SystemErrorList = loadable(() => import('@pages/notifications/system-error'))
const SystemUpdateList = loadable(() => import('@pages/system-update/system-update-list'))
const PowerBIReport = loadable(() => import('@pages/reports/PowerBIReport'))
const CountriesList = loadable(() => import('@pages/master-data/countries'))
const LanguagesList = loadable(() => import('@pages/master-data/languages'))
const CitiesList = loadable(() => import('@pages/master-data/cities'))
const MonthlyActiveAccountsList = loadable(() => import('@pages/system-reports/monthly-active-accounts'))
const BillingSettingList = loadable(() => import('@pages/system-reports/billing-setting'))
const TenantInvoiceList = loadable(() => import('@pages/system-subscriptions/tenant-invoice/listing'))
const TenantInvoiceDetail = loadable(() => import('@pages/system-subscriptions/tenant-invoice/detail'))
const AttachmentTypesList = loadable(() => import('@pages/master-data/attachment-types'))
const SystemUpdateDetail = loadable(() => import('@pages/system-update/system-update-detail'))
const BillingSetting = loadable(() => import('@pages/billing-settings'))
const ChatGPT = loadable(() => import('@pages/chatGPT'))
const TimeBudgetBreakdown = loadable(() => import('@pages/time-buget-break-down/list'))
const EditionTenantList = loadable(() => import('@pages/editions/list'))
const IMSApprovalCodeList = loadable(() => import('@pages/settings/ims-approval-code'))
const IMSApprovalDetail = loadable(() => import('@pages/settings/ims-approval-code/CreateForm'))
const MvaDetail = loadable(() => import('@pages/mva-claim/detail'))
const MvaList = loadable(() => import('@pages/mva-claim/list'))
const Notifications = loadable(() => import('@components/Notification/notification-list'))

const ServiceProviderList = loadable(() => import('@pages/service-provider/list'))
const ServiceProviderDetail = loadable(() => import('@pages/service-provider/details'))
const ProductUpdateDetail = loadable(() => import('@components/SystemUpdate/Detail'))
const VideoMeetingList = loadable(() => import('@pages/video-meeting/video-meeting-list'))
const VideoMeetingDetail = loadable(() => import('@pages/video-meeting/video-meeting-detail'))
const SessionVideoDetail = loadable(() => import('@pages/video-meeting/session-detail'))
const FundingTransactionInvoice = loadable(() => import('@pages/disbursement/transaction-invoice/detail'))
const FundingTransactionInvoiceLst = loadable(() => import('@pages/disbursement/transaction-invoice/listing'))
const MeetingListForCase = loadable(() => import('@pages/video-meeting/video-meeting-list/MeetingListForCase'))
const EmailSendingReport = loadable(() => import('@pages/reports/EmailSendingReport'))
const SystemTools = loadable(() => import('@pages/system-tools'))
const OutstandingBalanceReport = loadable(() => import('@pages/disbursement/outstanding-balance-report'))
const RelationshipActivity = loadable(() => import('@pages/reports/RelationshipActivity'))
const DocumentSearchPage = loadable(() => import('@pages/document-search'))
const AddEditTpd = loadable(() => import('@pages/tpd/tpd-details'))
const TpdList = loadable(() => import('@pages/tpd/tpd-list'))
export const DashboardRoutes: RouteConfig[] = [
  {
    path: '/',
    Component: DashboardRedirect,
    title: 'Dashboard',
    icontab: 'web-design',
  },
  {
    path: '/view/dashboard',
    Component: Dashboard,
    title: 'Dashboard',
    icontab: 'FundProjectionScreenOutlined',
  },
  {
    path: '/view/dashboard-disbursement',
    Component: DashboardDisbursement,
    title: 'Dashboard',
    icontab: 'FundProjectionScreenOutlined',
  },
  {
    path: '/view/system-tools',
    Component: SystemTools,
    title: 'System Tools',
    icontab: 'web-design',
  },
  {
    path: '/subscription',
    Component: SubscriptionPage,
    title: '',
    roles: [ROLE_NAME.ADMIN_STAFF],
  },
  // PROFILE
  {
    path: '/view/login-account',
    Component: MyProfile,
    title: 'My Details',
    icontab: 'UserOutlined',
  },
  {
    path: '/view/dr-account',
    Component: DoctorProfile,
    title: 'Doctor Profile',
    icontab: 'UserOutlined',
    roles: [ROLE_NAME.SPECIALIST],
  },
  {
    path: '/view/cm-profile',
    Component: CaseManagerProfile,
    title: 'CaseManager Profile',
    icontab: 'UserOutlined',
    roles: [ROLE_NAME.CASE_MANAGER],
  },
  // LOGOUT
  {
    path: '/view/logout',
    Component: () => <></>,
    title: 'Logout',
    icontab: 'log-out',
  },
  {
    path: '/view/theme-settings',
    Component: () => <></>,
    title: 'Theme Setting',
    icontab: 'log-out',
  },
  // DOCTOR ROUTES
  {
    path: '/view/universal-search',
    title: 'Case & Documents Search',
    Component: CasesSearch,
    icontab: 'SearchOutlined',
  },
  {
    path: '/view/docs-search',
    Component: DocsSearch,
    title: 'Document Search',
    icontab: 'SearchOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/action-required-search',
    Component: ActionRequiredSearch,
    title: 'Action Required & Next Task Search',
    icontab: 'SearchOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.fundingProvider.list,
    Component: FundingProviderList,
    title: 'Funding Provider',
    icontab: 'disbursement',
    children: [APP_ROUTES.fundingProvider.detail],
    features: [APP_FEATURES.Disbursement],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/outstanding-balance-report',
    Component: OutstandingBalanceReport,
    title: 'Outstanding Balance Report',
    icontab: 'disbursement',
    features: [APP_FEATURES.Disbursement],
    roles: [ROLE_NAME.ADMIN_STAFF],
  },
  {
    path: APP_ROUTES.mailTemplate.list,
    Component: MailTemplateList,
    title: 'System Mail Templates',
    icontab: 'mail-black',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.saleTarget.list,
    Component: SaleTargetList,
    title: 'Sale Targets',
    icontab: 'value-settings-tab',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/calendar',
    Component: SessionDetail,
    title: 'Clinic Calendar',
    icontab: 'Calendar',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER, ROLE_NAME.SPECIALIST],
  },
  // ADMIN ROUTES
  {
    path: APP_ROUTES.user.list,
    Component: AdminLoginUser,
    title: 'Login Users',
    icontab: 'UserOutlined',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.userGroup.list,
    Component: UserGroup,
    title: 'Staff Groups',
    icontab: 'UserOutlined',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.staff.list,
    Component: StaffList,
    title: 'Staff',
    icontab: 'UserOutlined',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF],
  },
  {
    path: APP_ROUTES.company.list,
    Component: CompanieList,
    title: 'Companies',
    icontab: 'Resources',
    children: [APP_ROUTES.company.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.company.detail,
    Component: CompanyDetail,
    title: 'Company Detail',
    icontab: 'Resources',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.company.add,
    Component: CompanyDetail,
    title: 'New Company',
    icontab: 'Resources',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.caseManager.list,
    Component: CaseManagerList,
    title: 'Case Managers',
    icontab: 'UserOutlined',
    children: [APP_ROUTES.caseManager.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.caseManager.detail,
    Component: CaseManagerDetail,
    title: 'Case Manager Detail',
    icontab: 'UserOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.caseManager.add,
    Component: CaseManagerDetail,
    title: 'New Case Manager',
    icontab: 'UserOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.contractor.list,
    Component: ContractorList,
    title: 'Contractors',
    icontab: 'UserOutlined',
    children: [APP_ROUTES.contractor.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.contractor.detail,
    Component: ContractorDetail,
    title: 'Contractor Details',
    icontab: 'UserOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.contractor.add,
    Component: ContractorDetail,
    title: 'New Contractor',
    icontab: 'UserOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.specialist.list,
    Component: SpecialistList,
    title: 'Specialists',
    icontab: 'UserOutlined',
    children: [APP_ROUTES.specialist.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.specialist.detail,
    Component: SpecialistDetail,
    title: 'Specialist Detail',
    icontab: 'UserOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.specialist.add,
    Component: SpecialistDetail,
    title: 'New Specialist',
    icontab: 'UserOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.centre.list,
    Component: CentreList,
    title: 'Service Centres',
    icontab: 'UserOutlined',
    children: [APP_ROUTES.centre.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.centre.detail,
    Component: CentreDetail,
    title: 'Centre Detail',
    icontab: 'UserOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.centre.add,
    Component: CentreDetail,
    title: 'New Centre',
    icontab: 'UserOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.marketingCampaign.list,
    Component: MarketingCampaignList,
    title: 'Marketing Campaigns',
    icontab: 'marketing-campaign',
    children: [APP_ROUTES.marketingCampaign.detail],
    features: [APP_FEATURES.MarketingCampaign],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.marketingCampaign.detail,
    Component: MarketingCampaignDetail,
    title: 'View Marketing Campaign',
    icontab: 'marketing-campaign',
    features: [APP_FEATURES.MarketingCampaign],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.marketingCampaign.add,
    Component: MarketingCampaignDetail,
    title: 'New Marketing Campaign',
    icontab: 'report-center-tab',
    features: [APP_FEATURES.MarketingCampaign],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.client.list,
    Component: ClientList,
    title: translate('Clients'),
    icontab: 'TeamOutlined',
    children: [APP_ROUTES.client.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CONTRACTOR, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.client.detail,
    Component: ClientDetail,
    title: `${translate('Client')} Detail`,
    icontab: 'TeamOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER, ROLE_NAME.CONTRACTOR],
  },
  {
    path: APP_ROUTES.client.add,
    Component: ClientDetail,
    title: `New ${translate('Client')}`,
    icontab: 'TeamOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER, ROLE_NAME.CONTRACTOR],
  },
  {
    path: '/view/lookup-config',
    Component: LookupConfig,
    title: 'Value List',
    icontab: 'setting-black',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/ad-hoc',
    Component: AdHocTemplate,
    title: 'Ad-Hoc Mail Templates',
    icontab: 'setting-black',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/holidays',
    Component: HolidayState,
    title: 'Public Holidays',
    icontab: 'setting-black',
  },
  {
    path: '/view/account-code',
    Component: AccountCode,
    title: 'Account Codes',
    icontab: 'setting-black',
  },
  {
    path: APP_ROUTES.imsApproval.list,
    Component: IMSApprovalCodeList,
    title: 'IMS Approval Codes (IRO)',
    icontab: 'setting-black',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.imsApproval.detail,
    Component: IMSApprovalDetail,
    title: 'Add IRO Code',
    icontab: 'setting-black',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/system-config',
    Component: SystemConfig,
    title: 'System Configuration',
    icontab: 'value-settings-tab',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.TENANT_ADMIN],
  },
  {
    path: '/hangfire',
    title: 'Hangfire Dashboard',
    icontab: 'processing-black',
    roles: [ROLE_NAME.TENANT_ADMIN],
  },
  {
    path: APP_ROUTES.assessment.list,
    Component: AssessmentList,
    title: 'IME Assessments (IM)',
    icontab: 'SnippetsOutlined',
    children: [APP_ROUTES.assessment.detail],
    props: { serviceType: IME_SERVICE_TYPE.IME },
    features: [APP_FEATURES.Assessment],
  },
  {
    path: APP_ROUTES.assessment.detail,
    fromModule: FROM_MODULE_TYPE.IME,
    Component: AssessmentDetail,
    title: 'View Assessment',
    icontab: 'SnippetsOutlined',
    props: { serviceType: IME_SERVICE_TYPE.IME },
    features: [APP_FEATURES.Assessment],
  },
  {
    path: APP_ROUTES.assessment.add,
    Component: AssessmentDetail,
    title: 'Add Assessment',
    icontab: 'SnippetsOutlined',
    props: { serviceType: IME_SERVICE_TYPE.IME },
    features: [APP_FEATURES.Assessment],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.massTorts.list,
    Component: AssessmentList,
    title: 'Mass Torts (MT)',
    icontab: 'CaseManagement',
    children: [APP_ROUTES.massTorts.detail],
    props: { serviceType: IME_SERVICE_TYPE.MT },
    features: [APP_FEATURES.MassTorts],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER, ROLE_NAME.SPECIALIST],
  },
  {
    path: APP_ROUTES.massTorts.detail,
    Component: AssessmentDetail,
    title: 'Mass Torts',
    icontab: 'CaseManagement',
    props: { serviceType: IME_SERVICE_TYPE.MT },
    features: [APP_FEATURES.MassTorts],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER, ROLE_NAME.SPECIALIST],
  },
  {
    path: APP_ROUTES.massTorts.add,
    Component: AssessmentDetail,
    title: 'Add Mass Torts',
    icontab: 'CaseManagement',
    props: { serviceType: IME_SERVICE_TYPE.MT },
    features: [APP_FEATURES.MassTorts],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER, ROLE_NAME.SPECIALIST],
  },
  {
    path: APP_ROUTES.ot.list,
    Component: AssessmentList,
    title: 'Occupational Therapy (OT)',
    icontab: 'SnippetsOutlined',
    children: [APP_ROUTES.ot.detail],
    props: { serviceType: IME_SERVICE_TYPE.OT },
    features: [APP_FEATURES.OT],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.SPECIALIST],
  },
  {
    path: APP_ROUTES.ot.detail,
    Component: AssessmentDetail,
    title: 'OT',
    icontab: 'SnippetsOutlined',
    props: { serviceType: IME_SERVICE_TYPE.OT },
    features: [APP_FEATURES.OT],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER, ROLE_NAME.SPECIALIST],
  },
  {
    path: APP_ROUTES.ot.add,
    Component: AssessmentDetail,
    title: 'Add OT',
    icontab: 'SnippetsOutlined',
    props: { serviceType: IME_SERVICE_TYPE.OT },
    features: [APP_FEATURES.OT],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER, ROLE_NAME.SPECIALIST],
  },
  {
    path: APP_ROUTES.supplementary.list,
    fromModule: FROM_MODULE_TYPE.SUPP,
    Component: SupplementaryReportList,
    title: 'Supplementary Reports (SR)',
    icontab: 'SnippetsOutlined',
    children: [APP_ROUTES.supplementary.detail],
    props: { serviceType: SUPP_SERVICE_TYPE.SUPP },
    features: [APP_FEATURES.Supplementary],
  },
  {
    path: APP_ROUTES.supplementary.detail,
    Component: SuppReportDetail,
    title: 'View Supplementary Report',
    icontab: 'SnippetsOutlined',
    props: { serviceType: SUPP_SERVICE_TYPE.SUPP },
    features: [APP_FEATURES.Supplementary],
  },
  {
    path: APP_ROUTES.supplementary.add,
    Component: SuppReportDetail,
    title: 'Add Supplementary Report',
    icontab: 'SnippetsOutlined',
    props: { serviceType: SUPP_SERVICE_TYPE.SUPP },
    features: [APP_FEATURES.Supplementary],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.fileReview.list,
    Component: SupplementaryReportList,
    title: 'File Reviews (FR)',
    icontab: 'SnippetsOutlined',
    children: [APP_ROUTES.fileReview.detail],
    props: { serviceType: SUPP_SERVICE_TYPE.FR },
    features: [APP_FEATURES.FileReview],
  },
  {
    path: APP_ROUTES.fileReview.detail,
    Component: SuppReportDetail,
    title: 'File Review',
    icontab: 'SnippetsOutlined',
    props: { serviceType: SUPP_SERVICE_TYPE.FR },
    features: [APP_FEATURES.FileReview],
  },
  {
    path: APP_ROUTES.fileReview.add,
    Component: SuppReportDetail,
    title: 'Add File Review',
    icontab: 'SnippetsOutlined',
    props: { serviceType: SUPP_SERVICE_TYPE.FR },
    features: [APP_FEATURES.FileReview],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.medicalRecord.list,
    Component: ClinicalRecordsRetrievalList,
    title: 'Medical Records Retrievals (MR)',
    icontab: 'SnippetsOutlined',
    children: [APP_ROUTES.medicalRecord.detail],
    features: [APP_FEATURES.MedicalRecord],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.medicalRecord.detail,
    Component: ClinicalRecordDetail,
    title: 'View Medical Record',
    icontab: 'SnippetsOutlined',
    features: [APP_FEATURES.MedicalRecord],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.medicalRecord.add,
    Component: ClinicalRecordDetail,
    title: 'Add Medical Record',
    icontab: 'SnippetsOutlined',
    features: [APP_FEATURES.MedicalRecord],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.tpd.list,
    Component: TpdList,
    title: 'TPD',
    icontab: 'SnippetsOutlined',
    children: [APP_ROUTES.tpd.detail],
    features: [APP_FEATURES.TPD],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.tpd.detail,
    Component: AddEditTpd,
    title: 'View TPD',
    icontab: 'SnippetsOutlined',
    features: [APP_FEATURES.TPD],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.tpd.add,
    Component: AddEditTpd,
    title: 'Add TPD',
    icontab: 'SnippetsOutlined',
    features: [APP_FEATURES.TPD],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  // {
  //   path: 'https://video.magconnect.com.au',
  //   title: 'Open VideoConnect',
  //   icon: 'documentary',
  //   icontab: 'file-management-black',
  // },
  {
    path: APP_ROUTES.invoice.list,
    Component: InvoiceList,
    title: 'Invoicing & Provider Payment',
    icontab: 'Accounting',
    children: [APP_ROUTES.invoice.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.invoice.detail,
    Component: InvoiceDetail,
    title: 'View Billing',
    icontab: 'Accounting',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.invoice.add,
    Component: InvoiceDetail,
    title: 'Create Billing',
    icontab: 'Accounting',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.amaServiceFee.list,
    Component: AMAFeeCodes,
    title: 'AMA Fee Codes',
    icontab: 'setting-black',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.serviceItem.list,
    Component: AdminServiceFee,
    title: 'Service Fee Schedule',
    icontab: 'setting-black',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.ndisServiceItem.list,
    Component: NDISServiceItem,
    title: 'NDIS (ND)',
    icontab: 'setting-black',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.talkingPoint.list,
    Component: TalkingPoint,
    title: 'Talking Point',
    icontab: 'setting-black',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.talkingPointCategory.list,
    Component: TalkingPointCategory,
    title: 'Talking Point Category',
    icontab: 'setting-black',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/generate-doctor-payment',
    Component: DoctorRemittance,
    title: 'Doctor Remittances',
    icontab: 'medical-doctor',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/video-session',
    Component: VideoSession,
    title: 'Video Session',
    icontab: 'file-management-black',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  // {
  //   path: '/view/dyte-video',
  //   Component: DyteApiDemo,
  //   title: 'Dyte Video Session',
  //   icon: 'inspection',
  //   icontab: 'file-management-black',
  // },
  {
    path: APP_ROUTES.videoMeeting.list,
    Component: VideoMeetingList,
    title: 'Telehealth Sessions',
    icontab: 'file-management-black',
    children: [APP_ROUTES.videoMeeting.detail],
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.videoMeeting.detail,
    Component: VideoMeetingDetail,
    title: 'Video Meeting Details',
    icontab: 'file-management-black',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/meeting-list-for-case',
    Component: MeetingListForCase,
    title: 'Video Meetings For Case',
    icontab: 'file-management-black',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/video-session-detail',
    Component: SessionVideoDetail,
    title: 'Video Session Detail',
    icontab: 'file-management-black',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/inquiry-centre',
    Component: InquiryCentre,
    title: 'Inquiry Centre',
    icontab: 'inquiry-centre',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/smart-search',
    Component: AppointmentSearch,
    title: 'Session & Specialist Search',
    icontab: 'SearchOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.medNeg.list,
    Component: MedNegList,
    title: 'Medical Negligence Triage (MN)',
    icontab: 'SnippetsOutlined',
    children: [APP_ROUTES.medNeg.detail],
    props: { serviceType: MN_SERVICE_TYPE.MN },
    features: [APP_FEATURES.MedNeg],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.medNeg.detail,
    Component: MedNegDetail,
    title: 'View Medical Negligence',
    icontab: 'SnippetsOutlined',
    props: { serviceType: MN_SERVICE_TYPE.MN },
    features: [APP_FEATURES.MedNeg],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.medNeg.add,
    Component: MedNegDetail,
    title: 'New Medical Negligence',
    icontab: 'SnippetsOutlined',
    props: { serviceType: MN_SERVICE_TYPE.MN },
    features: [APP_FEATURES.MedNeg],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.co.list,
    Component: AssessmentList,
    title: 'Conclave/Court Review (CT)',
    icontab: 'SnippetsOutlined',
    children: [APP_ROUTES.co.detail],
    props: { serviceType: IME_SERVICE_TYPE.CO },
    features: [APP_FEATURES.COTELE],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER, ROLE_NAME.SPECIALIST],
  },
  {
    path: APP_ROUTES.co.detail,
    Component: AssessmentDetail,
    title: 'View Conclave/Court Review',
    icontab: 'SnippetsOutlined',
    props: { serviceType: IME_SERVICE_TYPE.CO },
    features: [APP_FEATURES.COTELE],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER, ROLE_NAME.SPECIALIST],
  },
  {
    path: APP_ROUTES.co.add,
    Component: AssessmentDetail,
    title: 'Add Conclave/Court Review',
    icontab: 'SnippetsOutlined',
    props: { serviceType: IME_SERVICE_TYPE.CO },
    features: [APP_FEATURES.COTELE],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.tenant.list,
    Component: TenantList,
    title: 'Tenants',
    icontab: 'database-black',
    children: [APP_ROUTES.tenant.detail],
    roles: [ROLE_NAME.TENANT_ADMIN],
  },
  {
    path: APP_ROUTES.tenant.detail,
    Component: SystemConfig,
    title: 'System Configuration',
    icontab: 'value-settings-tab',
    roles: [ROLE_NAME.TENANT_ADMIN],
  },
  {
    path: '/view/roles',
    Component: RoleList,
    title: 'Roles',
    icontab: 'roles-black',
    children: ['/view/add-edit-roles'],
    roles: [ROLE_NAME.TENANT_ADMIN],
  },
  {
    path: '/view/remittance-statements',
    Component: SpecialistRemittance,
    title: 'Provider Remittance',
    icontab: 'Accounting',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/relationship-activities',
    Component: RelationshipActivity,
    title: 'Relationship Activity Tracking',
    icontab: 'Accounting',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/law-practice-softwares',
    Component: LawPracticeSoftwares,
    title: 'PMS Case & Matter',
    icontab: 'integration-black',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
    features: [APP_FEATURES.LegalPracticeAPI],
  },
  {
    path: '/view/pms-matter',
    Component: PMSMatters,
    title: 'PMS Matters',
    icontab: 'integration-black',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/clio-app',
    Component: ClioAppPage,
    title: 'Clio App',
    icontab: 'clio-app',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
    features: [APP_FEATURES.LegalPracticeAPI],
  },
  {
    path: '/view/leap-app',
    Component: LeapAppPage,
    title: 'Leap App',
    icontab: 'leap-app',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
    features: [APP_FEATURES.LegalPracticeAPI],
  },
  {
    path: '/view/pe-app',
    Component: PEAppPage,
    title: 'Practice Evolve App',
    icontab: 'pe-app',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
    features: [APP_FEATURES.LegalPracticeAPI],
  },
  {
    path: '/view/actionstep-app',
    Component: ActionStepAppPage,
    title: 'ActionStep App',
    icontab: 'actionstep-app',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
    features: [APP_FEATURES.LegalPracticeAPI],
  },
  {
    path: '/view/smokeball-app',
    Component: SmokeBallAppPage,
    title: 'Smokeball App',
    icontab: 'smokeball-app',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
    features: [APP_FEATURES.LegalPracticeAPI],
  },
  {
    path: '/view/pms-integration',
    Component: PMSIntegration,
    title: 'Case Records',
    icontab: 'pms-integration-tab',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
    features: [APP_FEATURES.LegalPracticeAPI],
  },
  {
    path: '/view/emails-tracking',
    Component: EmailTrackingList,
    title: 'Email Sent History',
    icontab: 'mail-black',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/system-error',
    Component: SystemErrorList,
    title: 'System Error',
    icontab: 'system-error',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.systemUpdate.list,
    Component: SystemUpdateList,
    title: 'System Updates',
    icontab: 'system-update-tab',
    children: [APP_ROUTES.systemUpdate.detail],
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF],
  },
  {
    path: '/view/emails-sending-report',
    Component: EmailSendingReport,
    title: 'Email Unprocessed Report',
    icontab: 'mail-black',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF],
  },
  {
    path: APP_ROUTES.systemUpdate.detail,
    Component: SystemUpdateDetail,
    title: 'View System Update',
    icontab: 'system-update-tab',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF],
  },
  {
    path: APP_ROUTES.systemUpdate.add,
    Component: SystemUpdateDetail,
    title: 'New System Update',
    icontab: 'system-update-tab',
    roles: [ROLE_NAME.TENANT_ADMIN],
  },
  {
    path: APP_ROUTES.fundingRequest.list,
    Component: FacilityRequestList,
    title: 'Facility Requests',
    icontab: 'FacilityDisbursement',
    children: [APP_ROUTES.fundingRequest.detail, APP_ROUTES.fundingRequest.add],
    features: [APP_FEATURES.Disbursement],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.fundingRequest.detail,
    Component: FacilityRequestDetail,
    title: 'View Facility Request',
    icontab: 'FacilityDisbursement',
    features: [APP_FEATURES.Disbursement],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.fundingRequest.add,
    Component: FacilityRequestDetail,
    title: 'New Facility Request',
    icontab: 'FacilityDisbursement',
    features: [APP_FEATURES.Disbursement],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.fundingAgreement.list,
    Component: ListingFacilityAgreement,
    title: 'Facility Agreements',
    icontab: 'FacilityDisbursement',
    children: [APP_ROUTES.fundingAgreement.detail, APP_ROUTES.fundingAgreement.add],
    features: [APP_FEATURES.Disbursement],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.fundingAgreement.detail,
    Component: AddEditFacilityAgreement,
    title: 'View Facility Agreements',
    icontab: 'FacilityDisbursement',
    features: [APP_FEATURES.Disbursement],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.fundingAgreement.add,
    Component: AddEditFacilityAgreement,
    title: 'New Facility Agreements',
    icontab: 'FacilityDisbursement',
    features: [APP_FEATURES.Disbursement],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.transactionInvoice.list,
    Component: FundingTransactionInvoiceLst,
    title: 'Funding Invoices',
    icontab: 'FacilityDisbursement',
    children: [APP_ROUTES.transactionInvoice.detail, APP_ROUTES.transactionInvoice.add],
    features: [APP_FEATURES.TransactionInvoice],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CONTRACTOR, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.transactionInvoice.add,
    Component: FundingTransactionInvoice,
    title: 'New Funding Invoice',
    icontab: 'FacilityDisbursement',
    features: [APP_FEATURES.Disbursement],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.transactionInvoice.detail,
    Component: FundingTransactionInvoice,
    title: 'View Funding Invoice',
    icontab: 'FacilityDisbursement',
    features: [APP_FEATURES.Disbursement],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.fundingTransaction.list,
    Component: FacilityTransactionList,
    title: 'Loan Fundings',
    icontab: 'FacilityDisbursement',
    children: [APP_ROUTES.fundingTransaction.detail, APP_ROUTES.fundingTransaction.add],
    features: [APP_FEATURES.FundingTransaction],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.fundingTransaction.add,
    Component: FacilityTransactionDetail,
    title: 'New Loan Funding',
    icontab: 'FacilityDisbursement',
    features: [APP_FEATURES.FundingTransaction],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.fundingTransaction.detail,
    Component: FacilityTransactionDetail,
    title: 'View Loan Funding',
    icontab: 'FacilityDisbursement',
    features: [APP_FEATURES.FundingTransaction],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.disbursementFunding.list,
    Component: DisbursementFundingList,
    title: 'Funding Transactions',
    icontab: 'FacilityDisbursement',
    children: [APP_ROUTES.disbursementFunding.detail, APP_ROUTES.disbursementFunding.add],
    features: [APP_FEATURES.DisbursementFunding],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.disbursementFunding.add,
    Component: DisbursementFundingDetail,
    title: 'New Funding Transactions',
    icontab: 'FacilityDisbursement',
    features: [APP_FEATURES.DisbursementFunding],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.disbursementFunding.detail,
    Component: DisbursementFundingDetail,
    title: 'View Funding Transactions',
    icontab: 'FacilityDisbursement',
    features: [APP_FEATURES.DisbursementFunding],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.checkList.list,
    Component: LMQList,
    title: 'LMQ',
    icontab: 'value-settings-tab',
    children: [APP_ROUTES.checkList.detail],
    features: [APP_FEATURES.LMQ],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.TENANT_ADMIN],
  },
  {
    path: APP_ROUTES.checkList.detail,
    Component: LMQDetail,
    title: 'View LMQ',
    icontab: 'invoice',
    features: [APP_FEATURES.LMQ],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.checkList.add,
    Component: LMQDetail,
    title: 'New LMQ',
    icontab: 'invoice',
    features: [APP_FEATURES.LMQ],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.timeTracking.list,
    Component: TimeTrackingList,
    title: 'Time Tracking',
    icontab: 'ClockCircleOutlined',
    children: [APP_ROUTES.timeTracking.detail],
    features: [APP_FEATURES.TimeTracking],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.timeTracking.add,
    Component: TimeTrackingDetail,
    title: 'Active Task Timing',
    icontab: 'ClockCircleOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.timeTracking.detail,
    Component: TimeTrackingDetail,
    title: 'Active Task Timing',
    icontab: 'ClockCircleOutlined',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.ndis.list,
    Component: NDISList,
    title: 'NDIS',
    icontab: 'CaseManagement',
    children: [APP_ROUTES.ndis.detail],
    features: [APP_FEATURES.NDIS],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.ndis.detail,
    Component: NDISDetails,
    title: 'NDIS',
    icontab: 'CaseManagement',
    features: [APP_FEATURES.NDIS],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.ndis.add,
    Component: NDISDetails,
    title: 'NDIS',
    icontab: 'CaseManagement',
    features: [APP_FEATURES.NDIS],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/service-analysis-report',
    Component: () => <PowerBIReport reportName="SERVICE_ANALYSIS_REPORT" />,
    title: 'Service Operation KPI',
    icontab: 'FundProjectionScreenOutlined',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/correspondence-analysis-report',
    Component: () => <PowerBIReport reportName="CORRESPONDENCE_REPORT" />,
    title: 'Relationship Correspondence',
    icontab: 'FundProjectionScreenOutlined',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/client-cm-recruitment',
    Component: () => <PowerBIReport reportName="CLIENT_AND_CM_RECRUITMENT_REPORT" />,
    title: 'CM Recruitment',
    icontab: 'FundProjectionScreenOutlined',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/billing-kpi-report',
    Component: () => <PowerBIReport reportName="BILLING_KPI_MAG_REPORT" />,
    title: 'Billing KPI (MAG)',
    icontab: 'FundProjectionScreenOutlined',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/iro-kpi-report',
    Component: () => <PowerBIReport reportName="IRO_KPI_REPORT" />,
    title: 'IRO KPI',
    icontab: 'FundProjectionScreenOutlined',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/bookings-report',
    Component: () => <PowerBIReport reportName="BOOKINGS_REPORT" />,
    title: 'Bookings Report',
    icontab: 'FundProjectionScreenOutlined',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/service-revenue-analysis-report',
    Component: () => <PowerBIReport reportName="SERVICE_REVENUE_REPORT" />,
    title: 'Service Revenue',
    icontab: 'FundProjectionScreenOutlined',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/service-revenue-master-report',
    Component: () => <PowerBIReport reportName="SERVICE_REVENUE_MASTER_REPORT" />,
    title: 'Service Revenue',
    icontab: 'dashboard-tab',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/user-account-analysis-report',
    Component: () => <PowerBIReport reportName="USER_ACCOUNT_REPORT" />,
    title: 'User Account',
    icontab: 'FundProjectionScreenOutlined',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/funding-analysis-report',
    Component: () => <PowerBIReport reportName="FUNDING_ANALYSIS_REPORT" />,
    title: 'Funding Analysis',
    icontab: 'dashboard-tab',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/disbursement-funding-report',
    Component: () => <PowerBIReport reportName="DISBURSEMENT_FUNDING_REPORT" />,
    title: 'Funding Transaction Report',
    icontab: 'dashboard-tab',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/specialist-recruitment-report',
    Component: () => <PowerBIReport reportName="SPECIALIST_RECRUITMENT_REPORT" />,
    title: 'Specialist Recruitment Report',
    icontab: 'FundProjectionScreenOutlined',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/booking-by-account-report',
    Component: () => <PowerBIReport reportName="BOOKING_BY_ACCOUNT_REPORT" />,
    title: 'Booking by account report',
    icontab: 'dashboard-tab',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/staff-kpi-report',
    Component: () => <PowerBIReport reportName="STAFF_KPI_REPORT" />,
    title: 'Staff KPI (MAG)',
    icontab: 'FundProjectionScreenOutlined',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/service-revenue-forecast-report',
    Component: () => <PowerBIReport reportName="SERVICE_REVENUE_FORECAST_REPORT" />,
    title: 'Service Revenue Forecast (MAG)',
    icontab: 'dashboard-tab',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.countries.list,
    Component: CountriesList,
    title: 'Countries',
    icontab: 'countries-tab',
    children: [APP_ROUTES.countries.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.cities.list,
    Component: CitiesList,
    title: 'Cities',
    icontab: 'city-tab',
    children: [APP_ROUTES.cities.detail],
  },
  {
    path: APP_ROUTES.monthlyActiveAccounts.list,
    Component: MonthlyActiveAccountsList,
    title: 'Tenant Monthly Usage',
    icontab: 'monthly-active-account',
    children: [APP_ROUTES.cities.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/client-active-count',
    Component: ClientActiveCount,
    title: 'Client Active Count',
    icontab: 'monthly-active-account',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF],
  },
  {
    path: APP_ROUTES.billingSettingList.list,
    Component: BillingSettingList,
    title: 'Tenant Billing Rates',
    icontab: 'monthly-active-account',
    children: [APP_ROUTES.cities.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.tenantInvoice.list,
    Component: TenantInvoiceList,
    title: 'Tenant Invoices',
    icontab: 'billing-tab',
    children: [APP_ROUTES.tenantInvoice.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.tenantInvoice.detail,
    Component: TenantInvoiceDetail,
    title: 'View Invoice',
    icontab: 'billing-tab',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.attachmentTypes.list,
    Component: AttachmentTypesList,
    title: 'Attachment Types',
    icontab: 'attach-tab',
    children: [APP_ROUTES.attachmentTypes.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.languages.list,
    Component: LanguagesList,
    title: 'Languages',
    icontab: 'language-tab',
    children: [APP_ROUTES.languages.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: APP_ROUTES.billingSetting.detail,
    Component: BillingSetting,
    title: 'Subscription',
    icontab: 'AppstoreAddOutlined',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF],
  },
  {
    path: '/view/chatGPT',
    Component: ChatGPT,
    title: 'ChatGPT',
    icontab: 'ChatGpt',
  },
  {
    path: APP_ROUTES.editions.list,
    Component: EditionTenantList,
    title: 'Editions',
    icontab: 'report-center-tab',
    roles: [ROLE_NAME.TENANT_ADMIN],
  },
  {
    path: '/view/time-budget-breakdown',
    Component: TimeBudgetBreakdown,
    title: 'Time Budget Breakdown',
    icontab: 'setting',
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/view/db-migration',
    Component: MigrationDB,
    title: 'DB-Migration',
    icontab: 'service-center-tab',
    roles: [ROLE_NAME.TENANT_ADMIN],
  },
  {
    path: APP_ROUTES.mvaClaim.detail,
    Component: MvaDetail,
    title: 'MVA Claim',
    icontab: 'CaseManagement',
    features: [APP_FEATURES.MVA],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER, ROLE_NAME.CONTRACTOR],
  },
  {
    path: APP_ROUTES.mvaClaim.add,
    Component: MvaDetail,
    title: 'MVA Claim',
    icontab: 'CaseManagement',
    features: [APP_FEATURES.MVA],
    children: [APP_ROUTES.mvaClaim.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER, ROLE_NAME.CONTRACTOR],
  },
  {
    path: APP_ROUTES.mvaClaim.list,
    Component: MvaList,
    title: 'MVA (MV)',
    icontab: 'CaseManagement',
    children: [APP_ROUTES.mvaClaim.detail],
    features: [APP_FEATURES.MVA],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER, ROLE_NAME.CONTRACTOR],
  },

  {
    path: APP_ROUTES.serviceProvider.detail,
    Component: ServiceProviderDetail,
    title: 'Service Provider',
    icontab: 'file-management-black',
    features: [APP_FEATURES.Disbursement],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.serviceProvider.add,
    Component: ServiceProviderDetail,
    title: 'Service Provider',
    icontab: 'file-management-black',
    features: [APP_FEATURES.Disbursement],
    children: [APP_ROUTES.serviceProvider.detail],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: APP_ROUTES.serviceProvider.list,
    Component: ServiceProviderList,
    title: 'Service Providers',
    icontab: 'file-management-black',
    children: [APP_ROUTES.serviceProvider.detail],
    features: [APP_FEATURES.Disbursement],
    roles: [ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/product-update',
    Component: ProductUpdateDetail,
    title: 'Product Update',
    icontab: 'system-update-tab',
  },
  {
    path: '/view/disbursement-funding-report',
    Component: () => <PowerBIReport reportName="DISBURSEMENT_FUNDING_REPORT" />,
    title: 'Funding Transaction Report',
    icontab: 'dashboard-tab',
    features: [APP_FEATURES.PowerBIReport],
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF, ROLE_NAME.STAFF],
  },
  {
    path: '/files/MAGDirectory',
    title: 'Specialist Panel',
    icontab: 'system-update-tab',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: 'https://www.youtube.com/channel/UC58K6nQi0LQ4HHNsNx2Xryw/videos',
    title: 'Seminars',
    icontab: 'system-update-tab',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: 'https://www.youtube.com/@MedicolegalAssessmentsGroup',
    title: 'Education Channel',
    icontab: 'system-update-tab',
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.CASE_MANAGER],
  },
  {
    path: '/view/documents-search',
    title: 'Documents Search',
    icontab: 'system-update-tab',
    Component: DocumentSearchPage,
    roles: [ROLE_NAME.TENANT_ADMIN, ROLE_NAME.ADMIN_STAFF],
  },
  {
    path: '/view/notifications',
    Component: Notifications,
    title: 'Notifications',
    icontab: 'notification-tab',
  },
]

const routes = DashboardRoutes.reduce((map, route) => {
  map[route.path] = route
  return map
}, {})

export default routes

function matchPathnameWithRoute(pathname, routePath) {
  // Convert the route path with :id to a regular expression
  const regex = new RegExp(
    '^' +
      routePath
        .replace(/:[^\s/]+/g, '([^/]+)')
        .replace(/\//g, '\\/')
        .replace(/\?/g, '\\?') +
      '$',
  )

  // Test if the pathname matches the regular expression
  const isMatch = regex.test(pathname)

  return isMatch

  // // If there is a match, extract the parameter values
  // if (match) {
  //   const params = routePath.match(/:[^\s/]+/g) || [];
  //   const paramValues = match.slice(1);
  //   const paramMap = {};
  //   params.forEach((param, index) => {
  //     paramMap[param.slice(1)] = paramValues[index];
  //   });
  //   return paramMap;
  // }

  // If there is no match, return null
}

class DashboardRoute {
  hasPathName = pathname => {
    return DashboardRoutes.some(route => {
      return matchPathnameWithRoute(pathname, route.path)
    })
  }

  getRouteByPathname = (pathname: string) => {
    return DashboardRoutes.find(route => {
      return matchPathnameWithRoute(pathname, route.path)
    })
  }
}

export const DashboardRouteService = new DashboardRoute()
